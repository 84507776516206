import styled from 'styled-components';

export const NewAddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  width: 100%;
  margin: 20px 0 10px;

  border-top: 1px solid #ccc;

  padding-top: 15px;
  padding-right: 30px;

  label {
    width: 100%;
  }
`;
