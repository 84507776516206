import React from 'react';
import { ICartItemDTO } from 'components/Cart';

import SemCapa from 'assets/livro-indefinido.png';
import { CartItemHolder } from './styles';

interface IProps {
  item: ICartItemDTO;
}

const CartItem: React.FC<IProps> = props => {
  const { item } = props;
  const { livro } = item;

  const precoTratado = livro.preco.replace('$', 'R$ ').replace('.', ',');
  const precoNumber = Number(livro.preco.replace('$', ''));

  const maiorParcela = livro.desconto10x
    ? { desconto: livro.desconto10x, parcela: 10 }
    : livro.desconto6x
    ? { desconto: livro.desconto6x, parcela: 6 }
    : livro.desconto3x
    ? { desconto: livro.desconto3x, parcela: 3 }
    : livro.desconto1x
    ? { desconto: livro.desconto1x, parcela: 1 }
    : undefined;

  const menorParcela = livro.descontoAvista
    ? { desconto: livro.descontoAvista, parcela: 0 }
    : livro.desconto1x
    ? { desconto: livro.desconto1x, parcela: 1 }
    : livro.desconto3x
    ? { desconto: livro.desconto3x, parcela: 3 }
    : livro.desconto6x
    ? { desconto: livro.desconto6x, parcela: 6 }
    : livro.desconto10x
    ? { desconto: livro.desconto10x, parcela: 10 }
    : { desconto: 0, parcela: 1 };

  return (
    <CartItemHolder key={item.id}>
      <img src={livro.foto || SemCapa} alt={livro.titulo} />
      <div className="titleHolder">
        <strong className="titulo">{livro.titulo}</strong>
        <span className="disciplina">{livro.disciplina}</span>
        <span className="serie">{livro.serie?.map(serie => serie.name)}</span>
      </div>
      <div className="priceHolder">
        <strong>
          R${' '}
          {menorParcela &&
            (precoNumber - (precoNumber * menorParcela.desconto) / 100)
              .toFixed(2)
              .replace('.', ',')}
          {menorParcela.parcela === 0 ? (
            <p>à vista</p>
          ) : (
            <p>em {menorParcela.parcela}x no cartão</p>
          )}
        </strong>
        <strong>
          {maiorParcela && (
            <>
              <p>ou em até</p> {maiorParcela.parcela}x de R${' '}
              {maiorParcela.desconto &&
                (
                  (precoNumber - (precoNumber * maiorParcela.desconto) / 100) /
                  maiorParcela.parcela
                )
                  .toFixed(2)
                  .replace('.', ',')}
            </>
          )}
        </strong>
      </div>
    </CartItemHolder>
  );
};

export default CartItem;
