import styled from 'styled-components';

export const CartHolder = styled.div`
  width: 80%;
  margin: 30px 0;
  ul {
    width: 100%;
    list-style: none;
  }
`;

export const Paginacao = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  padding: 15px 10px 0;

  button {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin: 0 2.5px;

    font-size: 12px;
    text-align: center;
    color: #fff;
    background-color: var(--main-bg);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    border: none;
    opacity: 0.8;
    border: 1px solid white;

    &.active {
      opacity: 1;
      border: 0px;
    }
    &:hover {
      opacity: 0.5;
    }
  }
`;

export const CartItemHolder = styled.li`
  width: 100%;
  min-height: 80px;
  border-left: 1px solid var(--main-bg);

  border-radius: 15px;
  padding: 10px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);

  margin: 10px 0;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  &:first-of-type {
    margin-top: 0px;
  }
  &:last-of-type {
    margin-bottom: 0px;
  }

  img {
    width: 20%;
    height: 100%;
  }
  div.titleHolder {
    flex: 1 1;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    padding-left: 5px;
    strong.titulo {
      line-height: 1.1;
      font-weight: 600;
    }
    span.disciplina {
      margin: 5px 0 10px;
      font-size: 14px;
    }
    span.serie {
      font-size: 14px;
    }
  }
  div.priceHolder {
    width: 30%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;

    strong {
      font-size: 14px;
      text-align: right;
      padding: 5px 0;
      &:first-child {
        border-bottom: 1px solid #333;
      }
      p {
        line-height: 1;
        font-size: 10px;
      }
    }
  }

  @media (max-width: 768px) {
    & {
      width: 100%;
      flex-direction: column;
      img {
        width: 75%;
      }
      div.titleHolder,
      div.priceHolder {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 90%;
      }
      div.titleHolder {
        padding-bottom: 15px;
        strong {
          text-align: center;
          margin-bottom: 15px;
        }
        span {
          width: 100%;
        }
        span.disciplina {
          margin-bottom: 5px;
        }
      }
      div.priceHolder {
        padding-top: 15px;
        border-top: 1px solid #ccc;
        strong {
          width: 90%;
          text-align: center;
          font-size: 16px;
          p {
            font-size: 12px;
          }
        }
      }
    }
  }
`;
