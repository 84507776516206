/* eslint-disable no-param-reassign */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { PageProps, navigate } from 'gatsby';
import Layout from 'components/_layout';
import { toast } from 'react-toastify';
import { emptyCart, ICartDTO, ICartItemDTO } from 'components/Cart';
import api from 'services/api';
import CartItem from 'components/CartItem';
import Radio from 'components/Radio';
import SelectUnform from 'components/Select';
import Input from 'components/Input';
import Textarea from 'components/Input/textarea';
import Button from 'components/Button';
import queryString from 'query-string';

import { Form } from '@unform/web';
import * as Yup from 'yup';
import getValidationErrors from 'utils/getValidationErrors';
import { FormHandles } from '@unform/core';
import { IEnderecoDTO, IOrderDTO } from 'utils/dto';
import cieloApi from 'services/cieloApi';
import CartShowDown from 'components/CartItem/cartShowDown';
import { FaPlus, FaPlusCircle } from 'react-icons/fa';
import NewAddress from 'components/NewAddress';
import {
  PageHolder,
  ContentHolder,
  ItemsHolder,
} from '../styles/pages/finalizar-compra';

interface SelectProps {
  value: string | number;
  label: string;
}

interface IFormData {
  tipoFrete: string;
  endereco?: string;
  observacao?: string;
  formaPagamento: string;
  parcela: number;
  frete?: number;
}

const FinalizarCompra = (props: PageProps) => {
  const { location } = props;

  const formRef = useRef<FormHandles>(null);

  const queryParams = queryString.parse(location.search);

  const [paymentOptions, setPaymentOptions] = useState<SelectProps[]>([]);
  const [enderecos, setEnderecos] = useState<SelectProps[]>([]);
  const [orderId, setOrderId] = useState<string | string[] | undefined>(
    undefined
  );
  const [alreadySended, setAlreadySended] = useState<boolean>(false);
  const [frete, setFrete] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [newAddressDisplay, setNewAddressDisplay] = useState<boolean>(false);

  const sendOrder = useCallback(() => {
    const cartBrute = localStorage.getItem('@FGLivraria:cart');

    if (!cartBrute) {
      return;
    }

    const cart: ICartDTO = JSON.parse(cartBrute);

    try {
      setLoading(true);
      api.defaults.headers.authorization = `Bearer ${localStorage.getItem(
        '@FGLivraria:token'
      )}`;
      api
        .post('/orders', {})
        .then(res => {
          const order_id = res.data.id;
          setOrderId(order_id);

          cart.items.forEach(item => {
            const item_data = {
              order_id,
              livro_id: item.livro.id,
              price: parseInt(item.livro.preco.replace('$', '')),
              quantity: item.quantity,
            };
            api
              .post('/order_item', item_data)
              .then(res_item => {
                cart.orderId = order_id;
                localStorage.setItem(
                  '@FGLivraria:activeOrder',
                  JSON.stringify(cart)
                );
                localStorage.removeItem('@FGLivraria:cart');
              })
              .catch(err => console.error(`Erro: ${err}`));
          });
        })
        .catch(err => {
          toast.error(
            '❌ Erro ao fazer seu pedido. Tente novamente mais tarde.',
            {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
          console.error(`Erro: ${err}`);
        });
    } catch (err) {
      toast.error('❌ Erro ao fazer seu pedido. Tente novamente mais tarde.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.error(`Erro: ${err}`);
    } finally {
      setLoading(false);
      emptyCart();
    }
  }, [setLoading, api, emptyCart]);

  const getActiveOrder = useCallback(() => {
    if (!localStorage.getItem('@FGLivraria:user')) {
      navigate('/minha-conta?redirect=123');
    } else if (
      !localStorage.getItem('@FGLivraria:activeOrder') &&
      localStorage.getItem('@FGLivraria:cartSession')
    ) {
      sendOrder();
    } else {
      const activeOrderInit = localStorage.getItem('@FGLivraria:activeOrder');

      if (!activeOrderInit) {
        return;
      }

      const activeOrder: ICartDTO = JSON.parse(activeOrderInit);

      if (activeOrder.items[0]) {
        const options: SelectProps[] = [];

        const {
          desconto1x,
          desconto3x,
          desconto6x,
          desconto10x,
          descontoAvista,
        } = activeOrder.items[0].livro;

        if (descontoAvista) {
          options.push({
            value: 0.1,
            label: `PIX`,
          });
          options.push({
            value: 0.2,
            label: `Depósito`,
          });
          options.push({
            value: 0.3,
            label: `Débito`,
          });
        }
        if (desconto1x) {
          options.push({
            value: 1,
            label: `1x no cartão`,
          });
        }
        if (desconto3x) {
          options.push({
            value: 3,
            label: `3x no cartão`,
          });
        }
        if (desconto6x) {
          options.push({
            value: 6,
            label: `6x no cartão`,
          });
        }
        if (desconto10x) {
          options.push({
            value: 10,
            label: `10x no cartão`,
          });
        }

        setPaymentOptions(options);
      }

      const { orderId: id } = activeOrder;

      if (!id) {
        const { order_id } = queryParams;
        if (order_id && !order_id.length) {
          setOrderId(order_id);
        }
      } else {
        setOrderId(id);
      }
    }
  }, []);

  useEffect(() => {
    getActiveOrder();
  }, [getActiveOrder]);

  const handleSubmit = useCallback(
    async (data: IFormData, { reset }) => {
      if (alreadySended) {
        toast.error('❌ Aguarde, carregando...', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }

      setAlreadySended(true);

      toast.success('✔️ Carregando...', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      try {
        if (!localStorage.getItem('@FGLivraria:token')) {
          toast.error('❌ Usuário não autenticado.', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          navigate('/');
          return;
        }
        api.defaults.headers.authorization = `Bearer ${localStorage.getItem(
          '@FGLivraria:token'
        )}`;

        setLoading(true);

        formRef.current?.setErrors({});

        let schema = Yup.object().shape({
          tipoFrete: Yup.string().required('Tipo do frete é obrigatório'),
          parcela: Yup.string().required(
            'Quantidade de parcelas é obrigatória'
          ),
          observacaoFrete: Yup.string(),
          endereco: Yup.string(),
        });

        if (data.tipoFrete === 'casa') {
          schema = Yup.object().shape({
            tipoFrete: Yup.string().required('Tipo do frete é obrigatório'),
            endereco: Yup.string().required('Endereço é obrigatório'),
            parcela: Yup.string().required(
              'Quantidade de parcelas é obrigatória'
            ),
            observacaoFrete: Yup.string(),
          });
          data.frete = 10;
        } else if (data.tipoFrete === 'colegio') {
          schema = Yup.object().shape({
            tipoFrete: Yup.string().required('Tipo do frete é obrigatório'),
            observacaoFrete: Yup.string().required(
              'As informações do estudantes para entrega são obrigatórias'
            ),
            parcela: Yup.string().required(
              'Quantidade de parcelas é obrigatória'
            ),
            endereco: Yup.string(),
          });
        }

        const parcelaOld = data.parcela;

        switch (data.parcela) {
          case 0.1:
            data.formaPagamento = 'pix';
            data.parcela = 0;
            break;
          case 0.2:
            data.formaPagamento = 'deposito';
            data.parcela = 0;
            break;
          case 0.3:
            data.formaPagamento = 'debito';
            data.parcela = 0;
            break;
          default:
            data.formaPagamento = 'credito';
            break;
        }

        await schema.validate(data, {
          abortEarly: false,
        });

        if (!orderId) {
          toast.error('❌ Pedido não iniciado. Tente novamente.', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return;
        }

        api
          .put(`/orders/lastinfos/${orderId}`, data)
          .then(res => {
            toast.success(
              '✔️ Atualização na ordem do pedido efetuada com sucesso.',
              {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );

            const orderData: IOrderDTO = res.data;

            // if (parcelaOld === 0.3 || parcelaOld === 1) {
            //   const cieloData: any = {
            //     OrderNumber: orderData.id,
            //     SoftDescriptor:
            //       'Compra dos livros didáticos da lista de materiais',
            //     Cart: {
            //       Items: [],
            //     },
            //     Shipping: {
            //       SourceZipCode: '82510050',
            //       TargetZipCode: orderData.endereco?.cep,
            //       Type: 'FixedAmount',
            //       Services: [
            //         {
            //           Name: 'Entrega na residência',
            //           Price: 10,
            //           Deadline: 10,
            //           Carrier: null,
            //         },
            //       ],
            //       Address: {
            //         Street: orderData.endereco?.endereco,
            //         Number: orderData.endereco?.numero,
            //         Complement: orderData.endereco?.complemento,
            //         District: orderData.endereco?.bairro,
            //         City: orderData.endereco?.cidade,
            //         State: orderData.endereco?.estado,
            //       },
            //     },
            //     Payment: {
            //       DebitDiscount: 10,
            //       Installments: null,
            //       MaxNumberOfInstallments: null,
            //     },
            //     Customer: {
            //       Identity: orderData.user.cpf,
            //       FullName: orderData.user.name,
            //       Email: orderData.user.email,
            //       Phone: orderData.user.telefone,
            //     },
            //     Options: {
            //       AntifraudEnabled: true,
            //       ReturnUrl: 'https://www.fglivraria.com',
            //     },
            //     Settings: null,
            //   };

            //   orderData.OrderItem?.forEach(item => {
            //     cieloData.Cart.Items.push({
            //       Name: item.livro.titulo,
            //       Description: item.livro.edicao,
            //       UnitPrice: item.livro.preco,
            //       Quantity: item.quantity,
            //       Type: 'Asset',
            //       Sku: item.livro.isbn,
            //       Weight: 1,
            //     });
            //   });
            //   cieloApi
            //     .post('/public/v1/orders', cieloData, {
            //       headers: {
            //         'Content-Type': 'application/json',
            //         MerchantId: '04541563-7ce4-4e02-bc39-a10df01ea1b9',
            //       },
            //     })
            //     .then(res => {
            //       toast.success(
            //         '✔️ Redirecionando para a página de pagamento...',
            //         {
            //           position: 'top-right',
            //           autoClose: 2000,
            //           hideProgressBar: false,
            //           closeOnClick: true,
            //           pauseOnHover: true,
            //           draggable: true,
            //           progress: undefined,
            //         }
            //       );
            //     })
            //     .catch(err => {
            //       console.log(err);
            //       toast.error(
            //         '❌ Erro ao ir para o pagamento. Tente novamente.',
            //         {
            //           position: 'top-right',
            //           autoClose: 3000,
            //           hideProgressBar: false,
            //           closeOnClick: true,
            //           pauseOnHover: true,
            //           draggable: true,
            //           progress: undefined,
            //         }
            //       );
            //       console.error(`Erro: ${err}`);
            //     });
            // }
            navigate(`/ordem-enviada?id=${orderId}`);
          })
          .catch(err => {
            setAlreadySended(false);
            toast.error(
              '❌ Erro na atualização na ordem do pedido. Tente novamente.',
              {
                position: 'top-right',
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
            console.error(`Erro: ${err}`);
          });
      } catch (err) {
        setAlreadySended(false);
        console.error(`Erro: ${err}`);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          let error =
            '❌ Erro na atualização na ordem do pedido. Tente novamente.';

          if (errors.tipoFrete) {
            error = `❌ ${errors.tipoFrete}`;
          } else if (errors.parcela) {
            error = `❌ ${errors.parcela}`;
          } else if (errors.observacaoFrete) {
            error = `❌ ${errors.observacaoFrete}`;
          } else if (errors.endereco) {
            error = `❌ ${errors.endereco}`;
          }

          toast.error(error, {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          return;
        }

        toast.error(
          '❌ Erro na atualização na ordem do pedido. Tente novamente.',
          {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      } finally {
        setLoading(false);
      }
    },
    [getValidationErrors, orderId, api, toast]
  );

  const getAddresses = useCallback(() => {
    if (!localStorage.getItem('@FGLivraria:token')) {
      toast.error('❌ Usuário não autenticado.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      navigate('/');
      return;
    }
    api.defaults.headers.authorization = `Bearer ${localStorage.getItem(
      '@FGLivraria:token'
    )}`;
    api.get('/enderecos/user').then(res => {
      const { data: initialData } = res;

      const data: SelectProps[] = [];

      for (let i = 0; i < initialData.length; i++) {
        const enderecoo = initialData[i];

        data.push({
          label: `${enderecoo.endereco}, ${enderecoo.numero}`,
          value: enderecoo.id,
        });
      }

      setEnderecos(data);
    });
  }, [newAddressDisplay]);

  useEffect(() => {
    getAddresses();
  }, [getAddresses]);

  const onFreteChange = useCallback(
    event => {
      setFrete(event.target.value);
    },
    [setFrete]
  );

  const newAddress = useCallback(() => {
    setNewAddressDisplay(!newAddressDisplay);
  }, [newAddressDisplay]);

  return (
    <Layout pathname={location.pathname}>
      <PageHolder>
        <h1>Finalizar compra</h1>
        <h2>
          Adicione os dados finais de compra e verifique se está tudo certo aqui
        </h2>

        <ContentHolder>
          <ItemsHolder className="info">
            <h3>Informações Adicionais de Compra</h3>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <div className="itens entrega">
                <strong>Entrega*:</strong>
                <span>
                  Aonde gostaria que a entrega dos livros seja efetuada?
                </span>
                <Radio
                  onClick={event => onFreteChange(event)}
                  name="tipoFrete"
                  options={[
                    { id: 'colegio', label: 'No colégio, para seu filho' },
                    { id: 'casa', label: 'Em casa (R$ 10,00)' },
                    { id: 'retirada', label: 'Retirada na loja' },
                  ]}
                />
              </div>
              {frete && (
                <div className="freteInfo">
                  {frete === 'colegio' ? (
                    <>
                      <label htmlFor="observacaoFrete">
                        Digite abaixo as informações do estudante para a entrega
                        em sala de aula (nome, série, colégio)*
                      </label>
                      <Textarea
                        name="observacaoFrete"
                        placeholder="Digite aqui as informações do estudante para entrega"
                      />
                    </>
                  ) : frete === 'casa' ? (
                    <>
                      <label htmlFor="endereco">
                        Selecione abaixo o endereço para entrega*
                      </label>
                      <div className="addressHolder">
                        <SelectUnform name="endereco" options={enderecos} />
                        <button type="button" onClick={newAddress}>
                          <FaPlusCircle size={20} />
                        </button>
                      </div>
                      <NewAddress
                        display={newAddressDisplay}
                        setDisplay={setNewAddressDisplay}
                      />
                    </>
                  ) : (
                    frete === 'retirada' && (
                      <>
                        <span>
                          Retire os livros no endereço abaixo:
                          <br />
                          Rua Estados Unidos, 400 Loja 2,
                          <br />
                          Bacacheri, Curitiba, Paraná
                        </span>
                      </>
                    )
                  )}
                </div>
              )}
              <div className="observacaoHolder">
                <strong>Alguma observação sobre a compra?</strong>
                <span>Envie-nos abaixo:</span>
                <Textarea
                  name="observacao"
                  placeholder="Digite aqui a observação"
                />
              </div>
              <div className="parcelaHolder">
                <strong>Forma de pagamento*:</strong>
                <span>Como você gostaria de efetuar a compra?</span>
                <SelectUnform name="parcela" options={paymentOptions} />
              </div>
              <Button disabled={loading} type="submit">
                {loading ? 'Carregando...' : 'Enviar para pagamento'}
              </Button>
            </Form>
          </ItemsHolder>
          <ItemsHolder className="items">
            <h3>Itens da compra</h3>
            <CartShowDown />
          </ItemsHolder>
        </ContentHolder>
      </PageHolder>
    </Layout>
  );
};

export default FinalizarCompra;
