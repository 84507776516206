import { ICartDTO, ICartItemDTO } from 'components/Cart';
import React, { useCallback, useEffect, useState } from 'react';
import CartItem from '.';
import { CartHolder, Paginacao } from './styles';

interface IPaginate {
  index: number;
  active: boolean;
}

const CartShowDown = () => {
  const [cartItems, setCartItems] = useState<ICartItemDTO[]>([]);
  const [cartItemsRender, setCartItemsRender] = useState<ICartItemDTO[]>([]);
  const [paginate, setPaginate] = useState<IPaginate[]>([]);

  const renderPage = (pag: number, cartItemsTemp: ICartItemDTO[]) => {
    const colegioArr: ICartItemDTO[] = [];

    for (let i = pag * 4; i < 4 * (pag + 1); i++) {
      if (cartItemsTemp[i]) {
        colegioArr.push(cartItemsTemp[i]);
      }
    }

    setCartItemsRender(colegioArr);
  };

  const changePage = (event: any, cartItemsTemp: ICartItemDTO[]) => {
    const tempPaginate = paginate;

    const { id } = event.target;

    const pagIndex = tempPaginate.find(pag => pag.index.toString() === id);

    if (pagIndex && pagIndex.active === false) {
      pagIndex.active = true;
      const otherPages = tempPaginate.filter(
        pag => pag.index.toString() !== id
      );

      otherPages.forEach(otherPag => {
        // eslint-disable-next-line no-param-reassign
        otherPag.active = false;
      });
    }

    setPaginate(tempPaginate);
    renderPage(parseInt(id) - 1, cartItemsTemp);
  };

  const getCartItems = useCallback(() => {
    const cart = localStorage.getItem('@FGLivraria:activeOrder');

    if (!cart) {
      return;
    }

    const parsedCart: ICartDTO = JSON.parse(cart);

    const data = parsedCart.items;

    const paginateArr: IPaginate[] = [];
    for (let i = 1; i <= Math.ceil(data.length / 4); i++) {
      paginateArr.push({
        index: i,
        active: i === 1,
      });
    }

    setPaginate(paginateArr);
    setCartItems(data);
    setCartItemsRender(data);
    renderPage(0, data);
  }, []);

  useEffect(() => {
    getCartItems();
  }, [getCartItems]);

  return (
    <CartHolder>
      <ul>
        {cartItemsRender &&
          cartItemsRender.map((item, index) => {
            if (index <= 4 - 1) {
              return <CartItem key={item.id} item={item} />;
            }
            return true;
          })}
      </ul>
      <Paginacao>
        {paginate.length > 1 &&
          paginate.map(pag => (
            <button
              key={pag.index}
              id={pag.index.toString()}
              className={pag.active ? 'active' : ''}
              type="button"
              onClick={(event: any) => changePage(event, cartItems)}
            >
              {pag.index}
            </button>
          ))}
      </Paginacao>
    </CartHolder>
  );
};

export default CartShowDown;
