import React, { useRef, useState, useCallback } from 'react';
import { IconBaseProps } from 'react-icons';
import ReactInputMask, { Props } from 'react-input-mask';

import { Container, Error } from './styles';

interface InputProps extends Props {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  iconSize?: number;
}

const MaskedInput: React.FC<InputProps> = ({
  icon: Icon,
  iconSize,
  name,
  ...rest
}) => {
  const inputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  return (
    <Container
      className="inputHolder"
      isFilled={isFilled}
      isFocused={isFocused}
    >
      {Icon && <Icon size={iconSize} />}
      <ReactInputMask
        id={name}
        name={name}
        onFocus={handleInputFocus}
        ref={inputRef}
        {...rest}
      />
    </Container>
  );
};

export default MaskedInput;
