import styled from 'styled-components';

export const PageHolder = styled.div`
  width: 100%;
  height: auto;

  margin: 40px 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h1 {
    color: var(--main-bg);
    font-size: 38px;
    font-weight: 600;
    text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  h2 {
    margin: 0 30px;
  }

  @media (max-width: 768px) {
    width: 95%;
    h2 {
      text-align: center;
      font-size: 20px;
    }
  }
`;

export const ContentHolder = styled.div`
  margin-top: 35px;

  width: 80%;
  height: auto;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: 1366px) {
    width: 90%;
  }

  @media (max-width: 768px) {
    & {
      width: 100%;
      flex-direction: column;
    }
  }
`;

export const ItemsHolder = styled.div`
  &.info {
    width: 60%;
  }
  &.items {
    width: 40%;
  }

  height: auto;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  padding: 30px;

  background: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  border-radius: 15px;

  &:first-of-type {
    margin-right: 20px;
  }

  h3 {
    width: 75%;
    font-size: 20px;
    color: var(--main-bg);
    padding: 0 0 10px;
    border-bottom: 2px solid var(--main-bg);
    text-align: center;
  }

  form {
    width: 75%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    div.itens {
      width: 100%;

      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 20px 30px;
      strong {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 5px;
      }
      span {
        font-size: 16px;
        margin-bottom: 10px;
      }
      .radioHolder {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        label.radio {
          margin: 5px 0;
          width: 100%;
          input {
            margin-right: 10px;
          }
        }
      }
    }
    div.freteInfo,
    div.parcelaHolder,
    div.observacaoHolder {
      width: 100%;
      padding: 20px 30px;
      border-top: 1px solid var(--main-bg);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      label {
        line-height: 1.2;
      }
      .inputHolder,
      .selectHolder,
      .textareaHolder {
        margin: 5px 0 15px;
        border-radius: 10px;
        textarea {
          resize: none;
        }
      }
      strong {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 5px;
      }
      span {
        font-size: 16px;
        margin-bottom: 10px;
        line-height: 1.4;
      }
    }
    button {
      margin: 0 auto;
      padding: 10px 30px;
      border-radius: 30px;
      text-align: center;
      border: 0;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      background-color: var(--main-color);
      color: var(--main-bg);
      font-weight: 500;
      font-size: 18px;
    }
  }

  div.freteInfo {
    border-top: 1px solid #ccc !important;
    div.addressHolder {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      margin-top: 10px;

      .selectHolder {
        flex: 1 1;

        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 0px;
      }
      button {
        height: 100%;
        width: auto;
        background: none;
        border: none;
        margin: 0px;
        margin-left: 10px;
        padding: 0px;
        color: #333;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }

  @media (max-width: 768px) {
    &.info {
      width: 100%;
    }
    &.items {
      width: 100%;
    }
    & {
      padding: 20px;
      margin-right: 0px;
      margin-bottom: 20px;
      h3,
      form {
        width: 90%;
      }
      form {
        div.itens,
        div.freteInfo,
        div.parcelaHolder,
        div.observacaoHolder {
          padding: 20px 15px;
        }
      }
    }
  }
`;
