import React, { useEffect, useRef, useState, useCallback } from 'react';
import ReactSelect, {
  OptionTypeBase,
  Props as SelectProps,
} from 'react-select';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';

import { Container, Error } from './styles';

interface Props extends SelectProps<OptionTypeBase> {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  iconSize?: number;
}

const SelectClean: React.FC<Props> = ({
  icon: Icon,
  iconSize,
  name,
  hasValue,
  ...rest
}) => {
  const selectRef = useRef<any>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const handleSelectFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleSelectChange = useCallback(() => {
    setIsFilled(true);
  }, []);

  return (
    <Container
      className="selectHolder"
      isFilled={isFilled}
      isFocused={isFocused}
    >
      {Icon && <Icon size={iconSize} />}
      <ReactSelect
        name={name}
        onChange={handleSelectChange}
        focus={handleSelectFocus}
        ref={selectRef}
        classNamePrefix="react-select"
        className="react-select"
        placeholder="Selecione..."
        noOptionsMessage={() => 'Nenhuma opção disponível'}
        {...rest}
      />
    </Container>
  );
};

export default SelectClean;
