import Button from 'components/Button';
import Input from 'components/Input/clean';
import Masked from 'components/Input/cleanMask';
import Select from 'components/Select';
import SelectClean from 'components/Select/clean';
import { navigate } from 'gatsby';
import React, { SetStateAction, useCallback, useRef, useState } from 'react';
import { FaMapMarkedAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';
import api from 'services/api';
import { NewAddressContainer } from './styles';

interface NewAddressI {
  display: boolean;
  setDisplay: React.Dispatch<SetStateAction<boolean>>;
}

const NewAddress: React.FC<NewAddressI> = props => {
  const { display, setDisplay } = props;

  const addressRef = useRef<HTMLDivElement>(null);

  const [estadosOptions, setEstadosOptions] = useState([
    { value: 'acre', label: 'Acre' },
    { value: 'alagoas', label: 'Alagoas' },
    { value: 'amazonas', label: 'Amazonas' },
    { value: 'amapá', label: 'Amapá' },
    { value: 'bahia', label: 'Bahia' },
    { value: 'ceará', label: 'Ceará' },
    { value: 'distrito federal', label: 'Distrito Federal' },
    { value: 'espírito santo', label: 'Espírito Santo' },
    { value: 'goiás', label: 'Goiás' },
    { value: 'maranhão', label: 'Maranhão' },
    { value: 'minas gerais', label: 'Minas Gerais' },
    { value: 'mato grosso do sul', label: 'Mato Grosso do Sul' },
    { value: 'mato grosso', label: 'Mato Grosso' },
    { value: 'pará', label: 'Pará' },
    { value: 'paraíba', label: 'Paraíba' },
    { value: 'pernambuco', label: 'Pernambuco' },
    { value: 'piauí', label: 'Piauí' },
    { value: 'paraná', label: 'Paraná' },
    { value: 'rio de janeiro', label: 'Rio de Janeiro' },
    { value: 'rio grande do norte', label: 'Rio Grande do Norte' },
    { value: 'rondônia', label: 'Rondônia' },
    { value: 'roraima', label: 'Roraima' },
    { value: 'rio grande do sul', label: 'Rio Grande do Sul' },
    { value: 'santa catarina', label: 'Santa Catarina' },
    { value: 'sergipe', label: 'Sergipe' },
    { value: 'são paulo', label: 'São Paulo' },
    { value: 'tocantins', label: 'Tocantins' },
  ]);

  const reset = useCallback(() => {
    const formEl = addressRef.current;

    if (formEl) {
      for (let i = 0; i < formEl.children.length; i++) {
        const el = formEl.children[i];

        if (
          el.tagName === 'DIV' &&
          (el.classList.contains('inputHolder') ||
            el.classList.contains('selectHolder'))
        ) {
          const el2: any = el.children[1];
          if (el2.tagName !== 'INPUT') {
            el2.children[1].value = '';
          } else {
            el2.value = '';
          }
        }
      }
    }
  }, [addressRef]);

  const newAddressSubmit = useCallback(() => {
    const formEl = addressRef.current;
    const data: any = {};

    const user: any = localStorage.getItem('@FGLivraria:user');
    if (!user) {
      toast.error('❌ Usuário não autênticado.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      navigate('/');
      return;
    }

    let id;
    if (user) {
      id = JSON.parse(user).id;
    }

    api.defaults.headers.authorization = `Bearer ${localStorage.getItem(
      '@FGLivraria:token'
    )}`;

    if (formEl) {
      for (let i = 0; i < formEl.children.length; i++) {
        const el = formEl.children[i];

        if (
          el.tagName === 'DIV' &&
          (el.classList.contains('inputHolder') ||
            el.classList.contains('selectHolder'))
        ) {
          const el2: any = el.children[1];
          const { id } = el2;
          let value;

          if (el2.tagName !== 'INPUT') {
            value = el2.children[1].value;
          } else {
            value = el2.value;
          }

          data[id] = value;
        }
      }
      data.user_id = id;

      api
        .post('/enderecos', data)
        .then(res => {
          toast.success(
            '✔️ Endereço criado com sucesso. Selecione-o e continue sua compra.',
            {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
          setDisplay(!display);
          reset();
        })
        .catch(err => {
          toast.error('❌ Erro ao criar novo endereço. Tente novamente.', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          console.error(`Erro: ${err}`);
        });
    }
  }, [addressRef, api, reset, toast, display]);

  return (
    <NewAddressContainer
      ref={addressRef}
      style={display ? {} : { display: 'none' }}
    >
      <label htmlFor="cep">CEP:</label>
      <Masked
        mask="99999-999"
        icon={FaMapMarkedAlt}
        iconSize={25}
        id="cep"
        name="cep"
        placeholder="Digite seu cep"
      />
      <label htmlFor="endereco">Endereço:</label>
      <Input
        icon={FaMapMarkedAlt}
        iconSize={25}
        id="endereco"
        name="endereco"
        placeholder="Digite seu endereco"
      />
      <label htmlFor="endereco">Número:</label>
      <Input
        icon={FaMapMarkedAlt}
        iconSize={25}
        id="numero"
        name="numero"
        placeholder="Digite seu numero"
      />
      <label htmlFor="endereco">Complemento:</label>
      <Input
        icon={FaMapMarkedAlt}
        iconSize={25}
        id="complemento"
        name="complemento"
        placeholder="Digite seu complemento"
      />
      <label htmlFor="endereco">Bairro:</label>
      <Input
        icon={FaMapMarkedAlt}
        iconSize={25}
        id="bairro"
        name="bairro"
        placeholder="Digite seu bairro"
      />
      <label htmlFor="endereco">Estado (UF):</label>
      <SelectClean
        options={estadosOptions}
        icon={FaMapMarkedAlt}
        iconSize={25}
        id="estado"
        name="estado"
        placeholder="Selecione seu estado"
      />
      <label htmlFor="endereco">Cidade:</label>
      <Input
        icon={FaMapMarkedAlt}
        iconSize={25}
        id="cidade"
        name="cidade"
        placeholder="Digite sua cidade"
      />
      <Button onClick={newAddressSubmit}>Novo Endereço</Button>
    </NewAddressContainer>
  );
};

export default NewAddress;
