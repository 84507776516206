import React, {
  ChangeEvent,
  ChangeEventHandler,
  useEffect,
  useRef,
} from 'react';
import { useField } from '@unform/core';

interface Option {
  id: string | number;
  label: string;
}

interface Props {
  name: string;
  def?: string | number;
  options: Option[];
}

type InputProps = JSX.IntrinsicElements['input'] & Props;

const Radio: React.FC<InputProps> = ({ name, options, def, onClick }) => {
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const { fieldName, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue(refs: HTMLInputElement[]) {
        const checked = refs.find(ref => ref.checked);

        return checked ? checked.value : null;
      },
      setValue(refs: HTMLInputElement[], value) {
        const item = refs.find(ref => ref.value === value);

        if (item) {
          item.checked = true;
        }
      },
    });
  }, [fieldName, registerField]);

  return (
    <div className="radioHolder">
      {options.map((option, index) => (
        <label className="radio" key={option.id}>
          <input
            onClick={onClick}
            ref={elRef => (elRef ? (inputRefs.current[index] = elRef) : null)}
            type="radio"
            name={fieldName}
            value={option.id}
            defaultChecked={def === option.id}
          />
          <span>{option.label}</span>
        </label>
      ))}
    </div>
  );
};

export default Radio;
